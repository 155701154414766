import React from 'react'

export default function Popup(props) {
   const { youWin, answer, restartGame, gameSettings } = props;

  return (
    <div id="popup" className="popup" onClick={() => restartGame(gameSettings)}>
      {youWin && (
        <div>
          <h2 className="win">You win!</h2>
        </div>
      )}

      {!youWin && (
        <div>
          <h2 className="lose">You lose!</h2>
          <p>The answer was "{answer}"</p>
        </div>
      )}
    </div>
  )
}
