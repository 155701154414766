import linkedinIcon from '../../assets/icons/icon-linkedin.svg';
const Footer = () => {

   return (
      <footer>
         <div className="empty"></div>
         <span>By <a href="https://michaelgredler.com.au/" target="_blank" rel="noreferrer">Michael Gredler</a>
         </span>
         <div className="social-icon">
            <a href="https://www.linkedin.com/in/mgredler/" target="_blank" rel="noreferrer">
               <img src={linkedinIcon} alt="linkedin icon" />
            </a>
         </div>
      </footer>
   )
};

export default Footer;