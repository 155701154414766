import React, { useState } from 'react';
import GameSettingsForm from '../GameSettingsForm';

const Header = ({ restartGame, handleWordSign, gameSettings, setGameSettings }) => {
   const [showMenu, setMenu] = useState(false);

   const handleBurger = () => {
      if(!showMenu) {
         setMenu(true);
         handleWordSign(false);
      } else {
         setMenu(false);
         handleWordSign(true);
      }
   }
   
   const handleSubmit = (settingsObject) => {
      console.log("settingsObject", settingsObject)
      setMenu(false);
      handleWordSign(true);
      restartGame(settingsObject);
   }

   return (
      <header>
         <div className="container">
            <h1>Verble</h1>
            <button className= {`hamburger ${showMenu ? "is-active" : ""}`} type="button" onClick={handleBurger}>
               <span className={`hamburger-box ${showMenu ? "is-active" : ""}`}>
                  <span className="hamburger-inner"></span>
               </span>
            </button>
         </div>
         <nav className={showMenu ? "active" : ""}>
            <div className="nav-container">
               <div className="title-wrap">
                  <h2>Word&nbsp;Types</h2>
               </div>
               <div className="menu-wrap">
                  <GameSettingsForm onSubmit={handleSubmit} showMenu={showMenu} gameSettings={gameSettings} setGameSettings={setGameSettings} />
               </div>
            </div>

         </nav>
      </header>
   )
};

export default Header;