import React from 'react'
import Row from './Row'

export default function Grid({ currentGuess, guesses, turn, answer }) {
   
  return (
    <section className="letter-grid">
      {guesses.map((guess, i) => {
        if(turn === i) {
          return <Row key={i} answer={answer} currentGuess={currentGuess} />
        }
        return <Row key={i} guess={guess} answer={answer} />
      })}
    </section>
  )
}
