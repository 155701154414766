import verbleDB from '../data/verble-db.json';

const getAnswer = (gameSettings) => {

   console.log("Word utilities - GAME SETTINGS", gameSettings)

   const { wordTypeArray, wordSize, upToExactly } = gameSettings;

   const verbArray = []
   const nounArray = []
   const adjectiveArray = []

   wordTypeArray.forEach((item) => {
      
      if(item === "verbs") {
         let wordBank = verbleDB.verbs.map((item) => item.verb);
         verbArray.push(wordBank)
      }
      
      if(item === "nouns") {
         let wordBank = verbleDB.nouns.map((item) => item.noun);
         nounArray.push(wordBank)
      }

      if(item === "adjectives") {
         let wordBank = verbleDB.adjectives.map((item) => item.adjective);
         adjectiveArray.push(wordBank)
         console.log("adjectiveArray", adjectiveArray)
      }
   });
   
   const wordBank = [...verbArray, ...nounArray, ...adjectiveArray].flat();

   
   const handleUpToExactly = (input) => {
      if(input === "Up to") {
         console.log("upto", wordSize)
         let limited = wordBank.filter((word) => word.length <= Number(wordSize));
         console.log("limited", limited)
         return limited;
      }
      if(input === "Exactly") {
         console.log("exactly", wordSize)
         let limited = wordBank.filter((word) => word.length === Number(wordSize));
         console.log("limited", limited)
         return limited;
      }
   }

   const wordBankLimited = handleUpToExactly(upToExactly);

   let randomIndex = Math.floor(Math.random() * wordBankLimited.length);
   const randomWord = wordBankLimited[randomIndex];
   return randomWord;


}

export {getAnswer};