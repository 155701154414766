import React from 'react'

export default function Row({ guess, answer, currentGuess }) {
   
   const emptySpacesArray = [...Array(answer.length).fill("")];
   if(guess) {
      return (
         <div className="row guess">
            {guess.map((letter, i) => {
           return <span className={`letterbox ${letter.color}`} style={{animationDelay: `${0.05*i}s`}}key={i}>{letter.key}</span>
        })}
         </div>
      )
   } else if(currentGuess) {
      const currentGuessArray = currentGuess.split('');

      return (
         <div className="row current">
            {currentGuessArray.map((letter, i) => {
               return <span className="letterbox just-typed" key={i}>{currentGuessArray[i]}</span>
            })}
            {[...Array(answer.length - currentGuessArray.length)].map((_, i) => {
               return  <div key={i} className="letterbox empty"></div>
            })}
              
            
         </div>
      )
   } else {
      return (
         <div className="row">
            {emptySpacesArray.map((letter, i) => {
               return <span className="letterbox" key={i}></span>
            })}
         </div>
      )
   }
}
