import React, { useEffect, useState } from 'react';
import useVerble from '../hooks/useVerble';
import Grid from './Grid';
import Keyboard from './Keyboard';
import Header from './layout/Header';
import Popup from './Popup';


export default function Verble({ answer, getNewAnswer, gameSettings, setGameSettings, handleBackground }) {
  const { currentGuess, handleKeyup, guesses, youWin, turn, usedKeys, handleKeyboardKey, fullWord, resetGame } = useVerble(answer);
  const [showPopup, setShowPopup] = useState(false);
  const [showSign, setShowSign] = useState(true);
  const [hideSign, setHideSign] = useState(false);
  const [wordSignLetters, setWordSignLetters] = useState(["v", "e", "r", "b", "s"]);

  const restartGame = (settingsObject) => {
    handleBackground();
    setWordSignLetters(handleWordSignLetters(settingsObject));
    setShowPopup(false);
    resetGame();
    setGameSettings(settingsObject);
    getNewAnswer(settingsObject);
  }

  const handleWordSignLetters = (input) => {
    const filteredArray = input.wordTypeArray.filter(item => item);
    
    console.log("filtered array: ", filteredArray)
    const joinedArray = filteredArray.join(" ")
    console.log("joined array: ", joinedArray)
    
    const signText = joinedArray.split('');
    console.log("sign text: ", signText)
    return signText;
  }

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);

    if(youWin) {
      if(!showPopup){
        setTimeout(() => setShowPopup(true), 700)
        window.removeEventListener('keyup', handleKeyup);
        return;
      }
    }
    
    if(turn > 5) {
      if(!showPopup){
        setTimeout(() => setShowPopup(true), 700)
        window.removeEventListener('keyup', handleKeyup);
        return;
      }
    }

    return () => window.removeEventListener('keyup', handleKeyup);

  }, [handleKeyup, youWin, turn, showPopup]);

  const handleWordSign = (value) => {
    if(value) {
      setHideSign(false)
      setTimeout(() => setShowSign(true), 1000);
    } else {
      setTimeout(() => setShowSign(false), 1000);
      setHideSign(true)
    }
  }

  return (
    <>
      <Header restartGame={restartGame} setWordSignLetters={setWordSignLetters} handleWordSign={handleWordSign} gameSettings={gameSettings} setGameSettings={setGameSettings} />
      <div className="game-wrap">
        <div className="ui-wrap">
          <Grid currentGuess={currentGuess} guesses={guesses} turn={turn} answer={answer} />
          <Keyboard usedKeys={usedKeys} onKeyboardKey={handleKeyboardKey} handleKeyup={handleKeyup} fullWord={fullWord} />
          <div className="word-sign">{wordSignLetters && wordSignLetters.map((letter, i) => {
            const delay = i * 0.075;
            if(letter === " ") {
              return <span key={i} style={{animationDelay: `${delay}s`}} className={`star ${showSign ? "show" : ""} ${hideSign ? "hide" : ""}`}>&#9734;</span>
              
            } else {
              return <span key={i} style={{animationDelay: `${delay}s`}} className={`${showSign ? "show" : ""} ${hideSign ? "hide" : ""}`}>{letter}</span>
            }
          })}</div>
        </div>
      </div>
      {showPopup && <Popup youWin={youWin} turn={turn} answer={answer} restartGame={restartGame} gameSettings={gameSettings} />}
    </>
  )
}
