import React, { useState, useEffect } from 'react';

// Checkbox Options
const wordTypesData = [
   {label: "Verbs", value: 'verbs', defaultChecked: true},
   {label: "Nouns", value: 'nouns', defaultChecked: false},
   {label: "Adjectives", value: 'adjectives', defaultChecked: false}
]

const Form = ({ onSubmit, showMenu, limit, gameSettings, setGameSettings }) => {
   
   const [formSettings, setFormSettings] = useState(gameSettings);
   const { wordTypeArray, wordSize, upToExactly } = formSettings;
   const [alertMessage, setAlertMessage] = useState(false);

   const checkedArray = wordTypesData.map((item) => {
      return (item.defaultChecked ? true : false);
   })
      
   const [checkedState, setCheckedState] = useState(checkedArray)
      
   // HANDLE CHECKBOX
   const handleCheckboxChange = (e, checkboxIndex) => {
      e.preventDefault();
      if(alertMessage) {
         setAlertMessage(false);
      }
      const newCheckedState = checkedState.map((item, i) => {
         if(i === checkboxIndex) {
            return item = !item;
         } else {
            return item;
         }
      })
         
      setCheckedState(newCheckedState);

      const newWordTypeState = newCheckedState.map((boolean, i) => {
         if(boolean) {
            return wordTypesData[i].value
         } else {
            return ''
         }
      })

      const newFormSettings = {...formSettings};
      newFormSettings.wordTypeArray = newWordTypeState;
      setFormSettings(newFormSettings);
      
      
   }
      
   const handleSlider = (e) => {
      const newFormSettings = {...formSettings};
      newFormSettings.wordSize = e.target.value;
      setFormSettings(newFormSettings);
   }

   const handleUpToExactly = (e) => {
      e.preventDefault();
      console.log("formSettings", formSettings)
      const newFormSettings = {...formSettings};
      if(upToExactly === "Up to") {
         newFormSettings.upToExactly = "Exactly";
      } else {
         newFormSettings.upToExactly = "Up to";
      }
      setFormSettings(newFormSettings);
   }

   const handleAlertMessage = () => {
      setAlertMessage(true);
      setTimeout(() => setAlertMessage(false), 5000)
   }

   // HANDLE SUBMIT
   const handleSubmit = (e) => {
      e.preventDefault();
      const validateWordType = wordTypeArray.filter((array) => array);
      console.log("validateWordType", validateWordType)
      if(validateWordType.length < 1) {
         handleAlertMessage();
      } else {
         onSubmit(formSettings);
      }
   }

   useEffect(() => {
      console.log("formSettings", formSettings)
      // setGameSettings((prev) => ({...prev, [wordTypes]: wordTypeState}));
   }, [formSettings])

   return (
         <form>
            <fieldset>
               {/* CHECKBOXES */}
               <ul className="wordtypes-list">
                  {wordTypesData.map((item, i) => {
                     return (
                           <li key={i}>
                           <input type="checkbox" id={item.value} name="wordtype" value={item.value} checked={checkedState[i]} onChange={(e) => handleCheckboxChange(e, i)}></input>
                           <button onClick={(e) => handleCheckboxChange(e, i)} className={`${item.value} ${checkedState[i] ? "checked" : ""}`} >{item.label}</button>
                        </li>
                     )
                  })}
                  {alertMessage && <div className="alert-message">Please choose a word type!</div>}
               </ul>


               <br></br>
                           
               {/* SLIDER */}
               <div className="range-slider-wrap">
                  <h3>Word size</h3>
                  <input onChange={handleSlider} type="range" id="wordsize" min="3" max="10" step="1" defaultValue="6" className="range-slider" />
               </div>
               {/* DROPDOWN */}
               <div className="uptoexactly">
                  <div className="btn-wrap">
                     <button className={`upto ${upToExactly === "Up to" ? "active" : ""}`} onClick={(e) => handleUpToExactly(e)}>Up to</button>
                     <button className={`exactly ${upToExactly === "Exactly" ? "active" : ""}`} onClick={(e) => handleUpToExactly(e)}>Exactly</button>
                  </div>
                  <span>{wordSize}</span>
               </div>


               {/* SUBMIT BUTTON */}
               <button className={`ok-btn ${alertMessage ? "alert" : ""} ${showMenu ? "animate" : ""}`} type="submit" onClick={handleSubmit}>
                  OK!
               </button>

            </fieldset>
         </form>
   );
}

export default Form;
