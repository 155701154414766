import { useState, useEffect } from  'react';
import {getAnswer} from './utilities/wordUtilities';
import Verble from './components/Verble';
import { Helmet } from 'react-helmet';

import catsPattern from './assets/images/pattern-cats.jpg';
import mexicanSkullsPattern from './assets/images/pattern-mexican-skulls.jpg';
import dndSkullsPattern from './assets/images/dnd-skulls-background.jpg'
import comicPattern from './assets/images/comic-background.jpg';
import heavyMetalPattern from './assets/images/heavy-metal-skulls-background.jpg';
import smileyPattern from './assets/images/smiley-background.jpg';
import Footer from './components/layout/Footer';

function App() {
  const [answer, setAnswer] = useState("");
  const [backgroundPattern, setBackgroundPattern] = useState("");

  const [gameSettings, setGameSettings] = useState({
    wordTypeArray: ['verbs'],
    wordSize: 6,
    upToExactly: 'Up to'
  })

  const getNewAnswer = (settingsObject) => {
    const newAnswer = getAnswer(settingsObject);
    console.log("getNewAnswer - answer", newAnswer)
    setAnswer(newAnswer);
  }

  const handleBackground = () => {
    const backgroundArray = [catsPattern, mexicanSkullsPattern, dndSkullsPattern, comicPattern, heavyMetalPattern, smileyPattern];
    let randomIndex = Math.floor(Math.random() * backgroundArray.length);
    const bg = backgroundArray[randomIndex];
    setBackgroundPattern(bg);
  }


  useEffect(() => {

    if(!answer) {
      const answer = getAnswer(gameSettings);
      console.log("USEeFFECT gamesettings", gameSettings)
      console.log("answer: ", answer)
      setAnswer(answer);
    }

    if(!backgroundPattern) {
      handleBackground();
    }

  }, [answer, backgroundPattern, gameSettings])

 

  return (
    <div className="app">

      <Helmet>
        <meta charSet="utf-8" />
        <title>Verble</title>
        <link rel="canonical" href="https://michaelgredler.com.au" />
      </Helmet>

      <main className="main" style={{backgroundImage: `url(${backgroundPattern})`}}>
        {answer && <Verble answer={answer} getNewAnswer={getNewAnswer} gameSettings={gameSettings} setGameSettings={setGameSettings} handleBackground={handleBackground} />}
      </main>
      <Footer />
    </div>
  );
}

export default App;
