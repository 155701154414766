import verbleDB from '../data/verble-db.json';

export default function Keyboard({ usedKeys, onKeyboardKey, handleKeyup, fullWord }) {
  const row01 = verbleDB.keys.row01;
  const row02 = verbleDB.keys.row02;
  const row03 = verbleDB.keys.row03;

  const handleKeyStroke = (key) => {
    console.log("handleKeyStroke", key)
    onKeyboardKey(key);
  }

  return (
    <section className="keyboard">
      <div className="key-row">
        {row01 && row01.map((letter) => {
          const color = usedKeys[letter.key];
          return (
            <span key={letter.key} className={color} onClick={
              () => handleKeyStroke(letter.key)}
              >{letter.key}</span>
          )
        })}
      </div>
      <div className="key-row">
        {row02 && row02.map((letter) => {
          const color = usedKeys[letter.key];
          return (
            <span key={letter.key} className={color} onClick={
              () => handleKeyStroke(letter.key)}>{letter.key}</span>
          )
        })}
      </div>
      <div className="key-row">
      <span 
        className={`enter ${fullWord ? "stroke" : ""}`} 
        onClick={() => handleKeyStroke("Enter")}
        >&#10004;
      </span>
        {row03 && row03.map((letter) => {
          const color = usedKeys[letter.key];
          return (
            <span key={letter.key} className={color} onClick={
              () => handleKeyStroke(letter.key)}>{letter.key}</span>
          )
        })}
        <span 
        className={`delete ${fullWord ? "stroke" : ""}`} 
        onClick={() => handleKeyStroke("Backspace")}
        >&#9003;
      </span>
      </div>
    </section>
  )
}
