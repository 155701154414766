import { useState } from 'react';

const useVerble = (answer) => {
   const [turn, setTurn] = useState(0);
   const [currentGuess, setCurrentGuess] = useState("");
   const [fullWord, setFullWord] = useState(false);
   const [guesses, setGuesses] = useState([...Array(6)]);
   const [youWin, setYouWin] = useState(false);
   const [usedKeys, setUsedKeys] = useState([])

   const resetGame = () => {
      setTurn(0);
      setCurrentGuess("");
      setFullWord(false);
      setGuesses([...Array(6)]);
      setYouWin(false);
      setUsedKeys([]);
   }

   const formatGuess = () => {
      let answerArray = [...answer];
      let formattedGuess = [...currentGuess].map((letter) => {
         return {key: letter, color: 'grey'}
      })

      // GREEN LETTERS
      formattedGuess.forEach((letter, i) => {
         if(answerArray[i] === letter.key) {
            letter.color = "green";
            answerArray[i] = null;
         }
      })

      // YELLOW LETTERS
      formattedGuess.forEach((letter, i) => {
         if(answerArray.includes(letter.key) && letter.color !== "green") {
            letter.color = "yellow";
            answerArray[answerArray.indexOf(letter.key)] = null;
         }
      })

      return formattedGuess;
   }

   const addNewGuess = (formattedGuess) => {
      if(currentGuess === answer) {
         setYouWin(true);
      }
      setGuesses((state) => {
         let newGuesses = [...state];
         newGuesses[turn] = formattedGuess;         
         return newGuesses;
      })
      setTurn((state) => {
         return state + 1;
      })


      setUsedKeys((state) => {
         let newKeys = {...state};
         console.log("newKeys", newKeys)
         console.log("formattedGuess", formattedGuess)
         formattedGuess.forEach((l) => {
            const currentColor = newKeys[l.key];
            console.log("currentColor", currentColor)
            if(l.color === 'green') {
               newKeys[l.key] = 'green';
               return;
            }
            if(l.color === 'yellow' && currentColor !== 'green') {
               newKeys[l.key] = 'yellow';
               return;
            }
            if(l.color === 'grey' && currentColor !== 'green' && currentColor !== 'yellow') {
               newKeys[l.key] = 'grey';
               return;
            }
         })
         return newKeys;
      })

      console.log("usedKeys", usedKeys)
      setCurrentGuess("");
   }

   const handleKeyup = ({ key }) => {

      if(currentGuess.length === answer.length - 1) {
         setFullWord(true);
      }

      if(key === 'Enter') {

         setFullWord(false);

         
         if(currentGuess.length < answer.length) {
            console.log("Word is too short!");
            return
         }

         const formattedGuess = formatGuess();
         console.log("currentGuess", currentGuess)
         addNewGuess(formattedGuess);
         
      }

      if(key === 'Backspace') {
         setFullWord(false);
         setCurrentGuess((prev) => {
            return prev.slice(0, -1)
         })
         return
      }

      if(/^[A-Za-z]$/.test(key)) {
         if(currentGuess.length < answer.length) {
            
            setCurrentGuess((prev) => {
               return prev + key;
            })
         }
      }
   }

   const handleKeyboardKey = (key) => {
      console.log("currentGuess length", currentGuess.length)
      console.log("answer length", answer.length)


      if(key === 'Enter') {
         setFullWord(false);

         
         if(currentGuess.length < answer.length) {
            console.log("Word is too short!");
            return
         }

         const formattedGuess = formatGuess();
         console.log("currentGuess", currentGuess)
         addNewGuess(formattedGuess);
         
      }

      if(key === 'Backspace') {
         setFullWord(false);
         setCurrentGuess((prev) => {
            return prev.slice(0, -1)
         })
         return
      }

      if(currentGuess.length === answer.length - 1) {
         setFullWord(true);
      }

      if(currentGuess.length < answer.length) {
         setCurrentGuess((prev) => {
            return prev + key;
         })
      }
   }

   return {turn, currentGuess, guesses, youWin, handleKeyup, usedKeys, handleKeyboardKey, fullWord, resetGame}

}

export default useVerble;